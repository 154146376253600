<script setup>
const props = defineProps({
  label: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  input_changed: {
    type: Function,
  },
});

const emit = defineEmits(['input-changed']);

const value = ref("");

watch(value, (newValue) => {
  emit('input-changed', newValue);
});
</script>

<template>
  <div class="customInput">
    <input
      class="customInput__input"
      required
      v-model="value"
      :placeholder="props.placeholder"
      :name="props.placeholder"
    />
    <span class="customInput__bar"></span>
    <label class="customInput__label">{{ $t(props.label) }}
    </label>
  </div>
</template>



<style lang="scss" scoped>

input:focus::-webkit-input-placeholder {
  opacity: 0;
}

.customInput__input:focus ~ .customInput__label,
.customInput__input:valid ~ .customInput__label {
  color: $white;
  font-weight: bold;
}

.customInput__input:focus ~ .customInput__bar:before,
.customInput__input:focus ~ .customInput__bar:after {
  width: 50%;
}

.customInput {
  max-width: 100%;
  position: relative;

  &__input {
    width: 100%;
    background: none;
    border: none;
    color: $white;
    font-style: normal;
    line-height: 143%;
    letter-spacing: -0.02em;
    font-size: 16px;
    padding: 25px 0 10px 0;
    outline: none;
    display: block;
    border-bottom: 1px solid $darkGrey;

    &::placeholder {
      color: $darkGrey;
    }
  }

  &__label {
    text-transform: uppercase;
    font-style: normal;
    line-height: 143%;
    letter-spacing: -0.02em;
    color: $darkGrey;
    font-size: 14px;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0px;
    transition: 0.2s ease all;
  }

  &__bar {
    position: relative;
    display: block;
    width: 100%;
  }

  &__bar:before,
  &__bar:after {
    content: "";
    height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: $white;
    transition: 0.2s ease all;
  }
  &__bar:before {
    left: 50%;
  }
  &__bar:after {
    right: 50%;
  }
}
</style>
